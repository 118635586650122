import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Button from "../../UI/Button"

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  padding: 80px 20px 110px;
  color: #fff;
  background-color: #4faae1;
  background-image: linear-gradient(90deg, #4faae1, #2b3d93);
  font-size: 1.3rem;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
  > :first-child {
    max-width: 500px;
  }
  > :last-child {
    width: 600px;
    height: 500px;
    > :first-child {
      height: 100%;
      width: 100%;
      img {
        object-fit: contain !important;
      }
    }
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    > :first-child {
      text-align: center;
    }
    > :last-child {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
  }
`

const Title = styled.h2`
  font-size: ${(props: { fontSize: string }) => props.fontSize || '4.5rem'};
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.1;
  @media (max-width: 1100px) {
    text-align: center;
  }
`

const Description = styled.div`
  margin-bottom: 15px;
`

interface IProps {
  image: any
  title: string
  description: string
  button: {
    value: string
    targetUrl: string
  }
}


// 15 -> 4.5em
// 100 -> 3em

const BooksHeroUI = (props: IProps) => {
  const titleFontSize = `${1 / props.title.length * 65}em`
  return <Wrapper>
    <Content>
      <div>
        <Title fontSize={titleFontSize}>{props.title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: props.description }} />
        {props.button.targetUrl[0] === "/" ? (
          <Link to={props.button.targetUrl}>
            <Button size="large" backgroundColor="#CF0384">
              {props.button.value}
            </Button>
          </Link>
        ) : (
          <a href={props.button.targetUrl}>
            <Button size="large" backgroundColor="#CF0384">
              {props.button.value}
            </Button>
          </a>
        )}
      </div>
      <div>
        <GatsbyImage
          image={getGatsbyImage(props.image.localFile)}
          alt="Books"
        />
      </div>
    </Content>
  </Wrapper>
}

const BooksHeroDefault = (props: IProps) => {
  const { strapiHomepage } = useStaticQuery(graphql`
    {
      strapiHomepage {
        BooksHero {
          button {
            targetUrl
            theme
            value
          }
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, width: 600)
              }
            }
          }
          title
        }
      }
    }
  `)

  return (
    <BooksHeroUI
      title={props.title || strapiHomepage.BooksHero.title}
      description={props.description || strapiHomepage.BooksHero.description}
      image={props.image || strapiHomepage.BooksHero.image}
      button={props.button || strapiHomepage.BooksHero.button}
    />
  )
}

const BooksHero = (props: IProps) => {
  if (props.button && props.description && props.image && props.button) {
    return <BooksHeroUI {...props} />
  }
  return <BooksHeroDefault {...props} />
}

export default BooksHero
