import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Button from '../../UI/Button'

const Wrapper = styled.section`
  background-color: #8cc24b;
  background-image: linear-gradient(45deg ,rgb(140, 194, 75),#d4df3e);
  padding-top: 20px;
  padding-bottom: 20px;
  color: #175923;
  font-size: 1.3rem;
`

const Content = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  > :first-child {
    margin-right: 40px;
    flex: 2 1 0%;
    > :nth-child(2) {
      margin-top: -10px;
      margin-bottom: 10px;
    }
  }
  > :nth-child(2) {
    flex: 1 1 0%;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    > * { max-width: 650px; }
    > :first-child {
      display: flex;
      flex-direction: column;
      margin-right: 0;
    }
    > :nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const Title = styled.h2`
  color: #fff;
  font-size: 1.9rem;
`

const Video = styled.video`
  width: 100%    !important;
  height: auto   !important;
  background: #175923;
  @media (max-width: 1100px) {
    margin-bottom: 30px;
  }
`

const Quote = styled.div`
  font-style: italic;
`

const QuoteAuthor = styled.div`
  font-size: 1rem;
  margin-top: 10px;
`

const Thumbnail = styled.div`
  width: 353px;
  margin: 20px 0;
`

const Description = styled.div`
  margin-bottom: 20px;
`

const ParentsHero = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
    {
      strapiHomepage {
        TeacherHero {
          button {
            id
            targetUrl
            theme
            value
          }
          description
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, width: 353)
              }
            }
          }
          quote {
            authorName
            authorTitle
            value
          }
          title
          video {
            url
          }
        }
      }
    }
  `)

  return <Wrapper>
    <Content>
      <div>
        <Title>{strapiHomepage.TeacherHero.title}</Title>
        <div>At School</div>
        <Video controls src={`${strapiHomepage.TeacherHero.video.url}#t=0.1`} preload="metadata" />
      </div>
      <div>
        <Quote>{strapiHomepage.TeacherHero.quote.value}</Quote>
        <QuoteAuthor>{strapiHomepage.TeacherHero.quote.authorName}, {strapiHomepage.TeacherHero.quote.authorTitle}</QuoteAuthor>
        <Thumbnail>
          <GatsbyImage image={getImage(strapiHomepage.TeacherHero.image.localFile)} alt={strapiHomepage.TeacherHero.quote.value || ''} />
        </Thumbnail>
        <Description>{strapiHomepage.TeacherHero.description}</Description>
        <Link to={strapiHomepage.TeacherHero.button.targetUrl}>
          <Button backgroundColor='#0E7034' size='regular'>
            {strapiHomepage.TeacherHero.button.value}
          </Button>
        </Link>
      </div>
    </Content>
  </Wrapper>
}

export default ParentsHero
