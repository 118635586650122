import { graphql, useStaticQuery } from "gatsby"

const useStrapiDefaults = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
  {
    strapiHomepage {
      ParentProductHero {
        title
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        kidImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, width: 520)
            }
          }
        }
        quote {
          authorName
          authorTitle
          value
        }
        button {
          value
          theme
          targetUrl
        }
      }
    }
  }
  `)

  return {
    backgroundImage: strapiHomepage.ParentProductHero.backgroundImage,
    title: strapiHomepage.ParentProductHero.title,
    kidImage: strapiHomepage.ParentProductHero.kidImage,
    quote: strapiHomepage.ParentProductHero.quote,
    button: strapiHomepage.ParentProductHero.button
  }
}

export default useStrapiDefaults