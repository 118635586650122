import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import getGatsbyImage from 'utils/getGatsbyImage'
import Button from '../../UI/Button'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  padding: 30px 20px 0;
  color: #7d2f91;
  font-size: 1.5rem;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
  > :first-child {
    max-width: 500px;
  }
  > :last-child {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    > :first-child {
      text-align: center;
    }
  }
`

const Title = styled.h2`
  font-size: 4.5rem;
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.1;
  @media (max-width: 1100px) {
    text-align: center;
  }
`

const Description = styled.div`
  margin-bottom: 15px;
`

const ShopHero = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
  {
    strapiHomepage {
      ShopHero {
        button {
          targetUrl
          theme
          value
        }
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, width: 500)
            }
          }
        }
        title
      }
    }
  }
  `)

  return <Wrapper>
    <Content>
      <div>
        <Title>{strapiHomepage.ShopHero.title}</Title>
        <Description>{strapiHomepage.ShopHero.description}</Description>
        <Link to={strapiHomepage.ShopHero.button.targetUrl}>
          <Button size='large' backgroundColor='#463044'>{strapiHomepage.ShopHero.button.value}</Button>
        </Link>
      </div>
      <div>
        <GatsbyImage image={getGatsbyImage(strapiHomepage.ShopHero.image.localFile)} alt='Shirts' />
      </div>
    </Content>
  </Wrapper>
}

export default ShopHero 
