import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  padding: 30px 20px 0;
  color: #fff;
  font-size: 1.6rem;
  background-image: linear-gradient(90deg, #58595b, #231f20);
  padding-top: 50px;
  padding-bottom: 20px;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1100px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > :first-child,
  > :nth-child(3) {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    flex: 1;
    width: 100%;
  }
  > :nth-child(2) {
    font-size: 1.6rem;
    text-align: center;
    flex: 1;
    width: 100%;
    margin: 0;
    font-weight: 400;
    line-height: 1.1;
    padding: 0 50px;
  }
`

const Partner = styled.div`
  margin-bottom: 30px;
`

const PartnersHero = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
    {
      strapiHomepage {
        PartnersGroup {
          id
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, width: 1100)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Content>
        {strapiHomepage.PartnersGroup.map(p => (
          <Partner key={p.id}>
            <Title>
              <div />
              <h3>{p.title}</h3>
              <div />
            </Title>
            <GatsbyImage
              image={getGatsbyImage(p.image.localFile)}
              alt={p.title || ""}
            />
          </Partner>
        ))}
      </Content>
    </Wrapper>
  )
}

export default PartnersHero
