import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Button from "../../UI/Button"
import useStrapiDefaults from "./useStrapiDefaults"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  padding: 30px 20px;
  margin: 0 auto;
  > :first-child {
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  > :last-child {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    > :first-child {
      width: 100%;
      height: 100%;
    }
    z-index: -1;
  }
  font-size: 1.3rem;
  @media (max-width: 1120px) {
    > :first-child {
      flex-direction: column-reverse;
    }
  }
`

const KidImage = styled.div`
  margin-bottom: -35px;
  max-width: 467px;
`

const Content = styled.div`
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > :last-child {
    align-self: flex-end;
  }
  @media (max-width: 1120px) {
    text-align: center;
    > :last-child {
      align-self: center;
    }
  }
`

const Title = styled.h2`
  font-size: 1.9rem;
  font-weight: bold;
`

const Quote = styled.div`
  margin-bottom: 35px;
`

const QuoteAuthor = styled.div`
  margin-bottom: 20px;
  text-align: end;
  font-weight: 700;
  @media (max-width: 1120px) {
    text-align: center;
  }
`

interface IProps {
  backgroundImage: any
  title: string
  kidImage: any
  quote: {
    title: string
    value: string
    authorName: string
    authorTitle: string
  }
  button: {
    targetUrl: string
    theme: "primary" | "confirm"
    value: string
  }
}

const ParentsHero = (props: IProps) => (
  <Wrapper>
    <div>
      <KidImage>
        <GatsbyImage
          image={getGatsbyImage(props.kidImage.localFile)}
          alt="Kid holding a tablet"
        />
      </KidImage>
      <Content>
        <Title>{props.title}</Title>
        <Quote>{props.quote.value}</Quote>
        <QuoteAuthor>
          {props.quote.authorName}, {props.quote.authorTitle}
        </QuoteAuthor>
        <Link to={props.button.targetUrl}>
          <Button theme={props.button.theme} size="large">
            {props.button.value}
          </Button>
        </Link>
      </Content>
    </div>
    <div>
      <GatsbyImage
        image={getGatsbyImage(props.backgroundImage.localFile)}
        alt="Night Zoo Forest"
      />
    </div>
  </Wrapper>
)

export const ParentsHeroDefault = () => {
  const defaultProps = useStrapiDefaults()
  return <ParentsHero {...defaultProps} />
}

export default ParentsHero
