import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import useRegisterModal from '../../RegisterModal/useRegisterModal'
import Button from '../../UI/Button'
import useStrapiDefaults from './useStrapiDefaults'

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  min-height: 400px;
  align-items: flex-end;
  padding-bottom: 40px;
`

const Content = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 0 20px;
  > :first-child {
    max-width: 480px;
    width: 100%;
    flex: 1;
  }
  > :nth-child(2) {
    flex: 1;
    width: 100%;
    margin-top: -80px;
    @media screen and (max-width: 560px) {
      display: none;
    }
  }
`

const Title = styled.h1`
  margin: 0;
  font-size: 4.5rem;
  margin-bottom: 40px;
`

const Teachers = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
  a {
    text-decoration: underline;
  }
`

const ChildLogin = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 279px;
  height: 250px;
  margin-left: auto;
  > :first-child {
    position: absolute;
    bottom: 20px;
  }
`

interface IProps {
  title: string
}

const ZooHero = (props: IProps) => {
  const [registerModal, toggleRegisterModal] = useRegisterModal()

  return <>
  <Wrapper>
    <StaticImage src='../../../images/zoo-bg.jpeg' alt='Enter the Zoo' />
    <Content>
      <div>
        <Title>{props.title}</Title>
        <Button theme='primary' size='regular' onClick={() => toggleRegisterModal()}>Register now</Button>
        <Teachers>Are you a teacher? <Link to='/teachers'>Discover</Link></Teachers>
      </div>
      <div>
        <a href='https://nightzookeeper.com/edu/zoo/login'>
          <ChildLogin>
            <StaticImage src='../../../images/characters/will-outline.png' alt='Will' width={200} placeholder='tracedSVG' />
            <Button size='regular' theme='the-pink'>Child Login</Button>
          </ChildLogin>
        </a>
      </div>
    </Content>
  </Wrapper>
  { registerModal }
  </>
}

export const ZooHeroDefault = () => {
  const defaultProps = useStrapiDefaults()
  return <ZooHero {...defaultProps} />
}

export default ZooHero
